import React from "react";
import navlinks from "./navlinks";

function useNavLinks({ spacerClassName, itemClassName } = {}) {
  return navlinks.map((item, i) => {
    let spacer = null;

    if (i < navlinks.length - 1) {
      spacer = (
        <span className={spacerClassName || "px-2 text-yellow-500"}>•</span>
      );
    }

    return (
      <React.Fragment key={item.path}>
        <a href={item.path} className={itemClassName}>
          {item.title}
        </a>
        {spacer}
      </React.Fragment>
    );
  });
}

export default useNavLinks;
