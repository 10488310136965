import React from "react";
import useNavLinks from "./useNavLinks";

export default function Header() {
  const links = useNavLinks();

  return (
    <nav
      className="flex flex-col sm:flex-row items-center justify-between py-6 px-8"
      data-nosnippet
    >
      <a href="/">
        <img
          src="/images/relevantum-logo.svg"
          className="logo pb-4 sm:pb-0"
          alt="Relevantum Oy"
        />
      </a>
      <div className="font-semibold text-center sm:text-left">{links}</div>
    </nav>
  );
}
