const navlinks = [
  {
    path: "/",
    title: "Kurssit"
  },
  {
    path: "/yritys",
    title: "Tietoa yrityksestä"
  },
  {
    path: "/ilmoittaudu-kurssille",
    title: "Ilmoittaudu kurssille"
  }
];

export default navlinks;
