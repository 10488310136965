import React from "react";
import Header from "./Header";

import "./Layout.css";
import useNavLinks from "./useNavLinks";

function Layout(props) {
  const navLinks = useNavLinks();

  return (
    <div className="container mx-auto bg-yellow-100 shadow-2xl relative">
      <Header />

      <div className="container px-8 py-5">{props.children}</div>

      <div className="text-gray-900 pt-10 pb-4 px-8 text-center">
        Relevantum Oy • Y-tunnus 0864388-7 • Tampere •{" "}
        <a
          href="mailto:kurssit@relevantum.fi"
          className="text-gray-900 no-underline"
        >
          kurssit@relevantum.fi
        </a>
      </div>

      <div
        className="text-gray-700 text-sm text-center px-8 pb-10"
        data-nosnippet
      >
        {navLinks}
      </div>
    </div>
  );
}

export default Layout;
